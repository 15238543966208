export const MAX_AIRCRAFT_LIST = 5;
export const REPORT_CART_TYPE = 'RC';
export const TREND_ANALYSIS_TYPE = 'TA';
export const NOT_READY_ARCHIVE_FOR_AIRCRAFT = 'not-ready';

export const TOO_FEW_FLIGHTS = 'too-few-flights';

export const SINGLE_ENGINE = 'SINGLE';
export const TWIN_ENGINE = 'TWIN';

export const BORESCOPE_ANALYSIS_TICKET_URL_QUERY = 'imageSet';
export const BORESCOPE_ANALYSIS_REPORT_TYPE = 'BA';

export const GROUP_BORESCOPE_SUBJECT = ['piston', 'exhaust', 'intake', 'cylinder'];
export const BAD_IMAGE_STATUS = 'bad-image';
export const NORMAL_STATUS = 'normal';

export const UPLOAD_BORESCOPE_PERMISSION = 'upload-borescope-images';
export const CREATE_BORESCOPE_REPORT_PERMISSION = 'create-borescope-reports';
export const REQUEST_ANALYSIS_BORESCOPE_PERMISSION = 'request-analysis-borescope';
export const BORESCOPE_PROGRESS_STATUS = 'IN_PROGRESS';
export const BORESCOPE_REQUEST_STATUS = 'ANALYSIS_REQUESTED';
export const BORESCOPE_SENT_STATUS = 'REPORT_SENT';
